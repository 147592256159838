import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import GoldCalculatorPage from './pages/GoldCalculatorPage';
import Notizie from './pages/Notizie';
import Storia from './pages/Storia';
import Scienza from './pages/Scienza';
import Vendita from './pages/Vendita';
import PrezzoRecord2024 from '../src/articles/2024/PrezzoRecord';
import ComeCalcolareOroUsato from './pages/ComeCalcolareOroUsato';
import GuidaInvestireOro from './articles/2024/GuidaInvestireOro';
import PercheOroBeneRifugio from './articles/2024/PercheOroBeneRifugio';
import OroArgentoTensioniIran from './articles/2024/OroArgentoTensioniIran';
import OroRecord from './articles/2024/OroRecord';
import PrevisioniPrezzoOro from './articles/2024/PrevisioniPrezzoOro';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {

  return (
    <div className="App">
      <Router>
      <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/calcolatrice-oro/" element={<GoldCalculatorPage />} />
          <Route exact path="/ultime-notizie-metalli-preziosi/" element={<Notizie />} />
          <Route exact path="/storia-del-oro/" element={<Storia />} />
          <Route exact path="/scienza-dell-oro/" element={<Scienza />} />
          <Route exact path="/acquisto-vendita-oro-italia/" element={<Vendita />} />
          <Route exact path="/articles/2024/prezzo-oro-nuovo-record-2384-dollari-oncia/" element={<PrezzoRecord2024 />} />
          <Route exact path="/calcolare-valore-oro-usato-guida-calcolatrice-online/" element={<ComeCalcolareOroUsato />} />
          <Route exact path="/articles/2024/guida-investire-in-oro-per-principianti/" element={<GuidaInvestireOro />} />
          <Route exact path="/articles/2024/perche-oro-bene-rifugio/" element={<PercheOroBeneRifugio />} />
          <Route exact path="/articles/2024/oro-argento-tensioni-iran/" element={<OroArgentoTensioniIran />} />
          <Route exact path="/articles/2024/oro-nuovo-record/" element={<OroRecord />} />
          <Route exact path="/articles/2024/previsioni-mercato-oro-2024/" element={<PrevisioniPrezzoOro />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
